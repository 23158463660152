<template>
  <div class="login-page">
    <div class="login-block">
      <div class="login-block-left">
        <div class="login-block-left-content">
          <router-link to="/">
            <img class="logo-img" src="@/assets/img/rightsholder_logo.svg" />
            <h1 class="login-headline">Private Beta Login</h1></router-link
          >
        </div>
      </div>
      <div class="login-block-right">
        <form class="login-form">
          <BaseStack vertical>
            <BaseStack class="form-block" vertical>
              <BaseLabel>Email</BaseLabel>
              <BaseInput
                v-model="email"
                class="menu-style"
                placeholder="Type in your email..."
                ref="emailInput"
                required
                name="email"
                type="email"
              />
            </BaseStack>

            <BaseStack class="form-block" vertical>
              <BaseLabel>Password</BaseLabel>
              <BaseInput
                v-model="password"
                class="menu-style"
                placeholder="Type in your password..."
                ref="passwordInput"
                name="password"
                type="password"
              />
            </BaseStack>

            <p v-if="loginApiStatus === API_STATUS.ERROR" class="error-message">
              {{ errorMessage }}
            </p>

            <div
              v-if="emailLoginLinkStatus === API_STATUS.SUCCESS"
              class="login-link-sent-message"
            >
              <BaseAlert variant="success">
                We have sent a login link to your email. Make sure you check
                your spam folder.
              </BaseAlert>
            </div>

            <div class="actions-row">
              <BaseButton
                class="login-btn"
                variant="primary-outline"
                @click.prevent="onLoginSubmit"
                :disabled="loginApiStatus === API_STATUS.PENDING"
                >{{
                  loginApiStatus === API_STATUS.PENDING
                    ? "Logging in..."
                    : "Login"
                }}</BaseButton
              >
              <BaseButton
                class="login-btn"
                variant="primary-outline"
                @click.prevent="emailLoginLink"
                :disabled="emailLoginLinkStatus === API_STATUS.PENDING"
                >{{
                  emailLoginLinkStatus === API_STATUS.PENDING
                    ? "Sending..."
                    : "Email Login Link"
                }}</BaseButton
              >
            </div>
          </BaseStack>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, sendEmailLoginLink } from "@/api/authApi.js";
import { API_STATUS } from "@/constants/apiStatus.js";
export default {
  data() {
    return {
      email: "",
      password: "",
      loginApiStatus: API_STATUS.IDLE,
      emailLoginLinkStatus: API_STATUS.IDLE,
      errorMessage: "dsadasdas",
    };
  },
  metaInfo: {
    title: "Login",
  },
  methods: {
    async emailLoginLink() {
      this.errorMessage = "";
      const { emailInput } = this.$refs;
      const isValidEmail = emailInput.$el.checkValidity();

      if (!isValidEmail) return;

      try {
        this.emailLoginLinkStatus = API_STATUS.PENDING;
        const settings = {};
        const { redirect } = this.$route.query;
        if (this.$route.query.redirect) {
          settings.redirect = redirect;
        }
        await sendEmailLoginLink(this.email, settings);
        this.emailLoginLinkStatus = API_STATUS.SUCCESS;
      } catch (error) {
        console.error(error);
        this.emailLoginLinkStatus = API_STATUS.ERROR;
        this.errorMessage = "There was a problem.";
      }
    },
    async onLoginSubmit() {
      this.errorMessage = "";
      const { emailInput, passwordInput } = this.$refs;
      const isValidEmail = emailInput.$el.checkValidity();
      const isValidPassword = passwordInput.$el.checkValidity();

      if (!isValidEmail || !isValidPassword) return;

      try {
        this.loginApiStatus = API_STATUS.PENDING;
        await login(this.email, this.password);
        this.loginApiStatus = API_STATUS.SUCCESS;
      } catch (error) {
        this.loginApiStatus = API_STATUS.ERROR;
        this.errorMessage = "Invalid username or Password";
      }
    },
  },
  created() {
    console.log("login app vue created");
    this.API_STATUS = API_STATUS;
  },
};
</script>

<style lang="scss" scoped>
$block-space: 2rem;

.login-link-sent-message {
  max-width: 33.5rem;
}

.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 1rem;

  @include tablet {
    padding: 0;
    align-items: center;
  }

  .login-block {
    display: flex;
    flex-direction: column;
    @include tablet {
      min-width: 80vw;
      flex-direction: row;
    }

    &-left {
      margin-bottom: 2rem;
      @include tablet {
        padding: 0 $block-space 0 $block-space;
        text-align: right;
        border-right: 5px solid $color-grey-light;
        margin-bottom: 0;
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;

        @include tablet {
          align-items: flex-end;
        }
      }
    }

    &-right {
      @include tablet {
        padding: $block-space 0 $block-space $block-space;
      }
    }

    &-left,
    &-right {
      flex-basis: 50%;
      flex-grow: 1;
    }
  }

  .login-headline {
    font-size: 26px;
    color: $color-primary;
    @include tablet {
      margin-right: 3rem;
      font-size: 32px;
    }
  }

  .logo-img {
    max-width: 80%;
    height: auto;
    margin-bottom: 1.5rem;
  }

  .login-form {
    max-width: 35rem;
  }

  .form-block {
    margin-bottom: 2rem;
  }

  .login-btn {
    min-width: 265px;
  }

  .error-message {
    margin: 0 0 2rem 0;
    color: $color-text-error;
  }

  .actions-row {
    display: flex;
    gap: 2rem;
  }
}
</style>